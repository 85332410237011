import http from "../http";

const common = {
    /*文件上传*/
    uploadFile: function (params) {
        return http.post('/api/v2/upload/image', params)
    },

    /*会员登录*/
    userLogin: function (params) {
        return http.post('/user/login', params)
    },
    /*会员退出*/
    userLogout: function (params) {
        return http.post('/user/logout', params)
    },
   
};

export default common
