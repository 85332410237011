import http from "../http";

const labrary = {
  /*获取图书馆分类*/
  getLabraryClass: function (params) {
    return http.get("/api/v2/bookclass", params);
  },
  getLabraryBook: function (params) {
    return http.get("/api/v2/book", params);
  },
  getLabraryChapter: function (params) {
    return http.get("/api/v2/bookchapter", params);
  },
  getLabraryContent: function (params) {
    return http.get("/api/v2/bookcontent", params);
  },

  setHistory: function (params) {
    return http.post("/api/v2/member/addbooks", params);
  },

  
};

export default labrary;
