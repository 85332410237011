import http from "../http";

const errorBook = {
  /*获取错题本科目目录*/
  getErrorBookNavList: function (params) {
    return http.get("/api/v2/upload/image", params);
  },
  /*获取错题本科目错题*/
  getErrorBookDataList: function (params) {
    return http.get("/api/v2/upload/image", params);
  },
};

export default errorBook;
